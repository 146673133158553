import React from "react"



const Error = () => {
  return (
    <div className="error-page">
      <div className="message-wrapper-error">
        <h1 className="final-action-title text-fa" style={{marginTop:0}}>404 <br /> Page not found</h1>
        <div className="load-more-btn">
          <a href="/" className="call-to-action" style={{marginTop:"-.5em"}}>Back Home</a>
        </div>
      </div>
    </div>
  )
}

export default Error
